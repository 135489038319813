<template>
  <div>
    <v-expand-transition>
      <OrderFilters v-show="showFilters" @clearFilterEvent="onClearFilterEvent" @applyFilterEvent="onApplyFilterEvent"></OrderFilters>
    </v-expand-transition>
    <v-expand-transition>
      <OrdersDownload4Metodo v-if="showDownloads && !!filters && !!showMetodoExport" :orders="orders"></OrdersDownload4Metodo>
    </v-expand-transition>
    <v-data-table dense :headers="headers" :items="orders" :search="search" :loading="!orders" :items-per-page="50" :loading-text="$t('loading')" sort-by="name,code,suff" class="elevation-1" :footer-props="{ showFirstLastPage: true, firstIcon: 'mdi-page-first', lastIcon: 'mdi-page-last', prevIcon: 'mdi-chevron-left', nextIcon: 'mdi-chevron-right'}">
      <template v-slot:item.exportedToMetodoDate="{ item }">
        <BooleanIcon :booleanValue="item.exportedToMetodoDate" :alt="item.exportedToMetodoDate | formatDate"/>
      </template>
      <template v-slot:item.orderDate="{ item }">
        <td style="word-break: keep-all">{{ item.orderDate | formatDate }}</td>
      </template>
      <template v-slot:item.orderTypeId="{ item }" style="max-width: 60px">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" class="bold">{{ getOrderTypeCode(item.orderTypeId) }}</span>
            </template>
            <span>{{ getOrderTypeDescription(item.orderTypeId) }}</span>
          </v-tooltip>
      </template>
      <template v-slot:item.customerBusinessName="{ item }" style="max-width: 60px">
        <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" class="bold" style="cursor:pointer;" @click="openCustomer(item.customerId)">
                {{ item.customerBusinessName }}
              </span>
            </template>
            <span>{{ getCustomerCode(item.customerId) }}</span>
          </v-tooltip>
      </template>
      <template v-slot:item.agentId="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                {{ getAgentShortName(item.agentId) }}
              </span>
            </template>
            <span v-html="getAgentsNames(item)"></span>
          </v-tooltip>
      </template>
      <template v-slot:item.orderStatusId="{ item }">
        <v-tooltip v-if="!isReadyToEvolve || !isWIP(item)" right>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-chip :color="getOrderStatusColor(item.orderStatusId)" text-color="white" style="font-weight: 600; font-size: 0.875rem;" @click="evolveOrderStatus()" >
                <v-icon class="roundChip" left>{{ getOrderStatusIcon(item.orderStatusId) }}</v-icon>
              </v-chip>
            </span>
          </template>
          <span>{{ getOrderStatus(item.orderStatusId) }}</span>
        </v-tooltip>
        <v-btn-toggle v-else>
          <v-btn color="success" @click="changeOrderStatus(item, 'PROCESSED')">
            <v-icon color="white" class="mr-2">mdi-progress-check</v-icon>
            {{ $t('processedUP') }}
          </v-btn>
          <v-btn color="info" @click="changeOrderStatus(item, 'INS')">
            <v-icon color="white" class="mr-2">mdi-progress-download</v-icon>
            {{ $t('ins') }}
          </v-btn>
        </v-btn-toggle>
      </template>
      <template v-slot:item.code="{ item }">
        <div style="display:flex; flex-direction: row;">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="secondary" dark v-bind="attrs" v-on="on" small>mdi-information-variant</v-icon>
            </template>
            <span>{{ getAuthUser(item.updateUser) }}</span>
          </v-tooltip>
          <span>{{ defaultTenant? item.code.substr(-5) : item.code }}</span>
        </div>
      </template>
      <template v-slot:item.customerPurchaseOrder="{ item }">
        <td style="font-size: 0.9rem;">{{ item.customerPurchaseOrder }}</td>
      </template>
      <template v-slot:item.accountingCurSubtotal="{ item }">
        <span class="bold">{{ item.accountingCurSubtotal | tenantCurrency }}</span>
      </template>
      <template v-slot:item.documentCurSubtotal="{ item }">
        {{ item.documentCurSubtotal | tenantCurrency }}
      </template>
      <template v-slot:item.estimatedDeliveryDate="{ item }">
        {{ item.estimatedDeliveryDate | formatDate }}
      </template>

      <template v-slot:top>
        <v-toolbar flat class="p">
          <v-toolbar-title>{{ $t('orders') }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mb-2 mr-2" color="info darker-2" dark v-bind="attrs" v-on="on"><v-icon>mdi-calendar</v-icon></v-btn>
            </template>
            <v-card>
              <v-card-title class="text-h5 grey lighten-2" v-t="'priorityOrders'"></v-card-title>
              <v-card-text>
                <DateFilter 
                  v-model="date" 
                  :onEnter="local_downloadPlanningExportByAurora" 
                  :onClear="clearedDate" 
                  :label="'date'" 
                  @change="local_downloadPlanningExportByAurora"
                ></DateFilter>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="local_downloadPlanningExportByAurora()" v-t="'download'"></v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn color="success" dark class="mb-2 mr-2" @click="downloadOrderListExport()"><v-icon>mdi-microsoft-excel</v-icon></v-btn>
          <v-btn v-if="!!filters && !!showMetodoExport" color="success" class="mb-2 mr-2" @click="showDownloads = !showDownloads"><v-icon>mdi-cloud-download</v-icon></v-btn>
          <v-btn v-if="!filters && !!showMetodoExport" color="grey" class="mb-2 mr-2" alt="Apply a filter" @click="snackbar = true"><v-icon>mdi-cloud-download</v-icon></v-btn>
          <v-snackbar v-model="snackbar">{{ text }}
            <template v-slot:action="{ attrs }">
              <v-btn color="warning" text v-bind="attrs" @click="snackbar = false" v-t="'close'"></v-btn>
            </template>
          </v-snackbar>
          <v-btn color="info" dark class="mb-2 mr-2" @click="showFilters = !showFilters">
            <v-icon>mdi-filter</v-icon>
          </v-btn>
          <v-btn color="primary" class="mb-2 mr-2" to="orders/new">{{ $t(hasOrderDrafts?'openDraft':'add') }}</v-btn>
          <v-btn v-show="hasOrderDrafts" color="error" dark class="mb-2" @click="deleteDraft">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <MFButtonPill :collection="getActionCollection(item)"></MFButtonPill>
      </template>
      <template v-slot:no-data>
        <NoDataImg/>
      </template>
      <template v-slot:body.append>
        <tr><td></td><td></td><td></td><td></td><td></td><td></td><td class="text-right">
          <span class="bold">{{ $t('total') }}:</span>
        </td><td><span class="bold">{{ ordersTotal | tenantCurrency }}</span></td><td></td><td></td><td></td><td></td></tr>
      </template>
    </v-data-table>
    <v-dialog v-if="showCloneOrderDialog" :value="true" width="1000">
      <UpdateOrderHeader :order="dolly" :isClone="true" @closeCloneOrderDialog="showCloneOrderDialog = !showCloneOrderDialog"></UpdateOrderHeader>
    </v-dialog>
    <v-dialog v-if="showPDFDialog" :value="true" width="400">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2" v-t="'selectLang'">
          
        </v-card-title>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="downloadOrderConfirmation(tempItem, 'it')">IT-IT</v-btn>
          <v-btn color="primary" text @click="downloadOrderConfirmation(tempItem, 'en-US')">EN-US</v-btn>
          <v-btn color="primary" text @click="downloadOrderConfirmation(tempItem, 'pt-BR')">PT-BR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MFButtonPill from '@/components/utility/MFButtonPill';
import OrderFilters from "@/components/filters/OrderFilters";
import OrdersDownload4Metodo from "@/components/downloads/OrdersDownload4Metodo";
import {call, get, sync} from "vuex-pathify";
import NoDataImg from '@/components/NoDataImg.vue';
import BooleanIcon from '@/components/app/BooleanIcon';
import UpdateOrderHeader from '../../components/view/UpdateOrderHeader.vue';

export default {
  components: {
    OrderFilters,
    OrdersDownload4Metodo,
    NoDataImg,
    BooleanIcon,
    UpdateOrderHeader,
    MFButtonPill
  },
  data: () => ({
    tempItem: null,
    dialog: false,
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    menu2: false,
    showCloneOrderDialog: false,
    showPDFDialog: false,
    dolly: {},
    getKey: () => Math.ceil(Math.random() * 9999),
    updateOrderModal: false,
    dialogDelete: false,
    search: "",
    showDownloads: false,
    snackbar: false,
    isReadyToEvolve: false,
    timeoutEvolution: null,
    editedIndex: -1,
    editedItem: { code: "", description: "", suffix: "" },
    defaultItem: { code: "", description: "", suffix: "" },
  }),
  computed: {
    headers(){
      return !!this.showMetodoExport
        ? [
          {text: "M", value: "exportedToMetodoDate", align: "center"},
          {text: this.$t('agent'), value: "agentId", align: "left"},
          {text: this.$t('type'), value: "orderTypeId", align: "left"},
          {text: this.$t('code'), align: "left", value: "code",},
          {text: this.$t('orderDate'), value: "orderDate", align: "left",},
          {text: this.$t('customer'), value: "customerBusinessName", align: "left",},
          {text: this.$t('po'), value: "customerPurchaseOrder", align: "left",},
          {text: this.$t('subTotal'), value: this.prefixValueSelector + "Subtotal", align: "right",},
          {text: this.$t('status'), value: "orderStatusId", align: "left",},
          {text: this.$t('shipmentDate'), value: "estimatedDeliveryDate", align: "left",},
          {text: this.$t('actions'), value: "actions", sortable: false, align: "center",}
        ] 
        : [
          {text: this.$t('agent'), value: "agentId", align: "left"},
          {text: this.$t('type'), value: "orderTypeId", align: "left"},
          {text: this.$t('code'), align: "left", value: "code",},
          {text: this.$t('orderDate'), value: "orderDate", align: "left",},
          {text: this.$t('customer'), value: "customerBusinessName", align: "left",},
          {text: this.$t('po'), value: "customerPurchaseOrder", align: "left",},
          {text: this.$t('subTotal'), value: this.prefixValueSelector + "Subtotal", align: "right",},
          {text: this.$t('status'), value: "orderStatusId", align: "left",},
          {text: this.$t('shipmentDate'), value: "estimatedDeliveryDate", align: "left",},
          {text: this.$t('actions'), value: "actions", sortable: false, align: "center",}
        ]
    },
    text(){
      return this.$t('setFilterMetodo');
    },
    getAgentShortName: get('agents/getAgentShortName'),
    modalService: sync("modalService"),
    hasOrderDrafts: get("orders/hasOrderDrafts"),
    orders: get("orders/orders"),
    bulkCustomers: get("customers/bulkCustomers"),
    agents: get("agents/agents"),
    authUsers: get("authUsers/authUsers"),
    orderDraft: sync("orders/orderDraft"),
    orderTypes: sync("orders/orderTypes"),
    orderStatus: sync("orders/orderStatus"),
    filters: sync("orders/filters"),
    ordersTotal: sync("orders/ordersTotal"),
    showFilters: sync("orders/showFilters"),
    showMetodoExport: get("tenantConfiguration/showMetodoExport"),
    prefixValueSelector: get("tenantConfiguration/prefixValueSelector"),
    defaultTenant: get("tenantConfiguration/default"),
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.initialize();
    this.clearedDate();
  },
  methods: {
    clearedDate() {
      let startDate = this.$moment().startOf('year');
      let todayDate = this.$moment().endOf('month');
      this.date = `${!!startDate ? startDate.format("DDMMYYYY") : ``}${!!startDate && !!todayDate? `..` : ``}${!!todayDate? todayDate.format("DDMMYYYY"): ``}`;
    },
    cloneOrder: function(order) {
      this.dolly = {...order};
      this.showCloneOrderDialog = !this.showCloneOrderDialog;
    },
    downloadPDFFile: function(order) {
      this.tempItem = {...order};
      this.showPDFDialog = !this.showPDFDialog;
    },
    evolveOrderStatus: function () {
      this.isReadyToEvolve = !this.isReadyToEvolve;
      this.timeoutEvolution = setTimeout(() => {
        this.isReadyToEvolve = !this.isReadyToEvolve;
      }, 5000);
    },
    isWIP: function (item) {
      return this.orderStatus.find(el => el.id === item.orderStatusId)?.code == 'WIP'
    },
    changeOrderStatus: function (order, status_code) {
      const selectedOrderStatus = this.orderStatus.find(x => x.code === status_code);
      if (selectedOrderStatus != null) {
        this.apiOrderIdChangeStatusToPatch({
          orderId: order.id,
          newStatusId: selectedOrderStatus.id
        }).then(() => {
          order.orderStatusId = selectedOrderStatus.id;
        });
      }
    },
    deleteDraft: function () {
      this.apiOrderDraftIdDelete(this.orderDraft.id);
    },
    closeModalOrder: function () {
      this.$emit("closeModalOrder");
      this.close();
    },
    initialize() {
      this.initOrderCollection();
      this.initBulkCustomersCollection();
      this.initAuthUserCollection();
      this.initAgentCollection();
      this.apiOrderDraftGet();
      this.orderTypesGet();
      this.initOrderStatusCollection();
    },
    getOrderStatus(orderStatusId) {
      return this.orderStatus.find(el => el.id == orderStatusId)?.description || "-";
    },
    getOrderStatusColor(orderStatusId) {
      return this.orderStatus.find(el => el.id == orderStatusId)?.code.toLowerCase() || null;
    },
    getOrderStatusIcon(orderStatusId) {
      const orderStatusCode = this.orderStatus.find(el => el.id === orderStatusId)?.code || null;
      switch (orderStatusCode) {
        case "TRANSMITTED":
          return "mdi-progress-wrench";
        case "WIP":
          return "mdi-progress-clock";
        case "PROCESSED":
          return "mdi-progress-check";
        case "CANCELED":
          return "mdi-progress-close";
        case "PENDING-AUTH":
          return "mdi-progress-alert";
        case "INS":
          return "mdi-progress-download";
      }
    },
    editItem(item) {
      this.editedIndex = this.orders.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.modalService.showUpdateOrderModal = true;
    },
    // deleteItem(item) {
    //   this.editedIndex = this.orders.indexOf(item);
    //   this.editedItem = Object.assign({}, item);
    //   this.modalService.showNewOrderModal = true;
    //   this.dialogDelete = true;
    // },
    // deleteItemConfirm() {
    //   this.deleteFamily(this.editedItem.id);
    //   this.closeDelete();
    // },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    getCustomerName: function (customerId) {
      return (this.bulkCustomers.find(x => x.id === customerId) || {}).businessName || "-";
    },
    getCustomerCode: function(customerId) {
      let customer = this.bulkCustomers.find(x => x.id === customerId);
      return `${(customer || {}).code || ``} - ${(customer || {}).businessName || ``}`; 
    },
    openCustomer: function(customerId) {
      this.$router.push('customers/' + customerId)
    },
    getOrderTypeCode: function (orderTypeId) {
      return (this.orderTypes.find(x => x.id === orderTypeId) || {}).code || "-";
    },
    getOrderTypeDescription: function (orderTypeId) {
      return (this.orderTypes.find(x => x.id === orderTypeId) || {}).description || "-";
    },
    getAuthUser: function (uuid) {
      const authUser = this.authUsers.find(x => x.uuid === uuid);
      return !!authUser? `${authUser.givenName} ${authUser.familyName}` : "-";
    },
    onClearFilterEvent() {
      this.showFilters = false;
      this.initOrderCollection(true);
    },
    onApplyFilterEvent() {
      this.applyFilters(this.filters);
    },
    getAgentsNames: function (item) {
      if (item.agentId === item.supervisorAgentId) {
        const agentName = ((this.agents.find(x => x.id === item.agentId) || {}).name || "-----");
        return `${agentName}`
      } else {
        const agentName = ((this.agents.find(x => x.id === item.agentId) || {}).name || "-----");
        const supervisorAgentName = ((this.agents.find(x => x.id === item.supervisorAgentId) || {}).name || "");
        return `${agentName}${supervisorAgentName? ` <br /> (${supervisorAgentName})` : ``}`;
      }
    },
    downloadOrderConfirmation: function (item, lang) {
      console.clear();
      console.log(item, lang);
      this.apiOrderConfirmation({
        orderId: item.id, 
        lang
      }).then((res) => {
        this.downloadPdf(`${item.code}-${lang}`, res);
      })
      .finally(() => {
        this.tempItem = null;
        this.showPDFDialog = !this.showPDFDialog;
      });
    },
    downloadOrderListExport: function () {
      const ordersId = this.orders.map(x => x.id);
      this.apiOrdersListExport(ordersId).then((res) => {
        this.downloadExcel(`orders_export`, res);
      });
    },
    downloadPlanningExport: function () {
      this.apiPlanningExport().then((res) => {
        this.downloadExcel(`PRIOrders`, res);
      });
    },
    downloadPlanningProcessedExport: function () {
      this.apiPlanningProcessedExport().then((res) => {
        this.downloadExcel(`PRI-PROCESSEDOrders`, res);
      });
    },
    downloadPdf: function (filename, content) {
      var binaryData = [];
      binaryData.push(content);
      const url = window.URL.createObjectURL(new Blob(binaryData));
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${filename}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    },
    downloadExcel: function (filename, content) {
      var binaryData = [];
      binaryData.push(content);
      const url = window.URL.createObjectURL(new Blob(binaryData));
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${filename}.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    },
    getTotal: function () {
      return this.orders.reduce((a, b) => {
        a = a || 0;
        a += b.accountingCurSubtotal;
        return a;
      }, 0);
    },
    getActionCollection: function (item) {
      return [
        // {color: "secondary", action: () => this.downloadOrderConfirmation(item), icon: "mdi-file-pdf-box"},
        {color: "secondary", action: () => this.downloadPDFFile(item), icon: "mdi-file-pdf-box"},
        {color: "secondary", action: () => this.$router.push(`/orders/${item.isHistorical? `historical/` : ``}${item.id}`), icon: "mdi-pencil"},
        {color: "secondary", action: () => this.cloneOrder(item), icon: "mdi-content-duplicate"}
      ]
    },
    local_downloadPlanningExportByAurora: function() {
      this.apiPlanningExportByAurora(this.date)
      .then((res) => {
        this.downloadExcel(`itemsOrdered_${this.date.replaceAll('..', '_')}.xlsx`, res);
        this.dialog = false;
        this.date = `${!!startDate ? startDate.format("DDMMYYYY") : ``}${!!startDate && !!todayDate? `..` : ``}${!!todayDate? todayDate.format("DDMMYYYY"): ``}`;
      });
    },  
    downloadExcel: function (filename, content) {
      var binaryData = [];
      binaryData.push(content);
      const url = window.URL.createObjectURL(new Blob(binaryData));
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${filename}.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    },
    ...call("orders", [
      "initOrderCollection",
      "applyFilters",
      "apiOrderDraftIdDelete",
      "apiOrderDraftGet",
      "apiOrderConfirmation",
      "apiOrdersListExport",
      "orderTypesGet",
      "initOrderStatusCollection",
      "apiOrderIdChangeStatusToPatch",
      "apiOrderCustomerIdGet",
      "apiPlanningExport",
      "apiPlanningProcessedExport",
      "apiPlanningExportByAurora"
    ]),
    ...call("customers", [
      "initBulkCustomersCollection"
    ]),
    ...call("agents", [
      "initAgentCollection"
    ]),
    ...call("authUsers", [
      "initAuthUserCollection"
    ]),
  },
};
</script>
<style>
.text-truncate {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.button-truncate {
  padding: 0
}
.bold {
  font-weight: bold;
}
.roundChip {
  margin-left: -6px !important;
  margin-right: -5px !important;
}
tr:nth-child(even) {background: rgb(235, 235, 235)}
tr:nth-child(odd) {background: rgb(255, 255, 255)}
tr:hover{background: rgb(215, 215, 215) !important}
.bgColor{background: transparent}
</style>
